<!-- =========================================================================================
    File Name: Login.vue
    Description: Login Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center" id="page-login">
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">

          <div class="vx-row no-gutter justify-center items-center">

            <div class="vx-col hidden lg:block lg:w-1/2">
              <img src="@/assets/images/pages/login.png" alt="login" class="mx-auto">
            </div>

            <div class="vx-col sm:w-full md:w-full lg:w-1/2 d-theme-dark-bg">
              <div class="p-8 login-tabs-container">

                <div class="vx-card__title mb-4">
                  <h4 class="mb-4">Iniciar sesión</h4>
                  <p>Bienvenido! Ingresá con tu email y contraseña.</p>
                </div>
                <form @submit.prevent="submitForm">
                  <div>
                    <vs-input
                      v-validate="'required'"
                      data-vv-validate-on="disable"
                      name="email"
                      icon-no-border
                      icon="icon icon-user"
                      icon-pack="feather"
                      label-placeholder="Email"
                      v-model="email"
                      @input="clear2FA()"
                      class="w-full"/>
                    <span class="text-danger text-sm">{{ errors.first('email') }}</span>

                    <vs-input
                      v-validate="'required|min:6'"
                      data-vv-validate-on="disable"
                      type="password"
                      name="password"
                      icon-no-border
                      icon="icon icon-lock"
                      icon-pack="feather"
                      label-placeholder="Contraseña"
                      v-model="password"
                      class="w-full mt-6" />
                    <span class="text-danger text-sm">{{ errors.first('password') }}</span>
                    
                    <div v-if="enable2FA">
                      <vs-input
                        v-validate="'required|min:6'"
                        data-vv-validate-on="disable"
                        autocomplete="off"
                        name="code"
                        icon-no-border
                        icon="icon icon-lock"
                        icon-pack="feather"
                        label-placeholder="Codigo de Seguridad"
                        v-model="code2FA"
                        class="w-full mt-6" />
                      <span class="text-danger text-sm">{{ errors.first('code') }}</span>
                    </div>

                    <div class="flex flex-wrap justify-between my-5">
                      <vs-checkbox v-model="remember" class="mb-3">Recordar</vs-checkbox>
                    </div>
                    <vs-button button="submit">Ingresar</vs-button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      email: '',
      password: '',
      remember: false,
      code2FA: '',
      enable2FA: false
    }
  },
  methods: {
    checkLogin () {
      // If user is already logged in notify
      if (this.$store.state.auth.isUserLoggedIn()) {

        this.$vs.notify({
          title: 'Login Attempt',
          text: 'You are already logged in!',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })

        return false
      }
      return true
    },
    submitForm () {
      // Validate form
      this.$validator.validate().then(valid => {
        if (valid) {
          this.loginJWT()
        }
      })
    },
    loginJWT () {
      if (!this.checkLogin()) return

      // Loading
      this.$vs.loading()

      const payload = {
        remember: this.remember,
        login: this.email,
        password: this.password,
        code2FA: this.code2FA || null
      }

      this.$store.dispatch('auth/loginJWT', payload)
        .then(() => { this.$vs.loading.close() })
        .catch(error => {
          if (error.status === 206) this.enable2FA = true

          this.$vs.loading.close()
          this.$vs.notify({
            title: 'Error',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        })
    },
    clear2FA () {
      if (!this.enable2FA) return
      this.enable2FA = false
      this.code2FA = ''
    }
  }
}
</script>

<style lang="scss">
#page-login {
  .social-login-buttons {
    .bg-facebook { background-color: #1551b1 }
    .bg-twitter { background-color: #00aaff }
    .bg-google { background-color: #4285F4 }
    .bg-github { background-color: #333 }
  }
}
</style>
