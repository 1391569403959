var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "vs-select",
    {
      staticClass: "w-full",
      attrs: { id: "programmatic-provider", clearable: false, multiple: "" },
      on: { input: _vm.setProviders },
      model: {
        value: _vm.providersSelected,
        callback: function ($$v) {
          _vm.providersSelected = $$v
        },
        expression: "providersSelected",
      },
    },
    [
      _vm._l(_vm.providers, function (item, index) {
        return _c("vs-select-item", {
          key: index,
          attrs: { value: item.value, text: item.text },
        })
      }),
      _c("span", { attrs: { slot: "no-options" }, slot: "no-options" }, [
        _vm._v("Nada que mostrar."),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }