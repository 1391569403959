export const filesDataGetter = (params) => {
    const {data} = params
    const {company, campaign} = data
  
    if(company.purchase_orders){
      return {
        campaign_id: campaign.id,
        is_programmatic: campaign.is_programmatic,
        files: company.purchase_orders.map((fileData) => fileData)
      }
    }
  
    return {
      campaign_id: campaign.id,
      is_programmatic: campaign.is_programmatic,
      files: []
    }
}

const getRateCurrency = (params, key) => {
  const rates = params.data.operation.rates
  if(!rates) return '-'
  const ratesParsed = JSON.parse(rates)

  if (Array.isArray(ratesParsed)) return '-'

  return ratesParsed[key].currency
}

const paymentGetters = {
  operation: {
    rate_from_currency: (params) => getRateCurrency(params, 'from'),
    rate_to_currency: (params) => getRateCurrency(params, 'to'),
  },
  campaign: {
    campaign_type: (params) => params.data.campaign.is_programmatic ? 'Programática' : 'Tradicional',
  }
}

export default paymentGetters;