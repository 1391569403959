import {
  addressGetter,
  agencyCommissionMethodGetter,
  billingMethodGetter,
  holdingIdGetter,
  holdingNameGetter,
  idClientGetter,
  legalNameGetter,
  paymentODOOMethodGetter,
  timeLimitGetter
} from '../strings'

export const columnDefs = () => [
  {
    headerName: 'Datos del cliente',
    headerClass: 'column-group-1',
    children: [
        {
            headerName: 'ID',
            field: 'id',
            width: 150,
            filter: true,
            checkboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            headerCheckboxSelection: true,
            pinned: 'left'
          },
          {
            headerName: 'Razón social',
            valueGetter: legalNameGetter,
            filter: false,
            width: 200
          },
          //From ODOO
          {
            headerName: 'ID anunciante',
            valueGetter: idClientGetter,
            filter: true,
            width: 200
          },
          {
            headerName: 'Nombre de fantasía',
            valueGetter: () => '-',
            filter: true,
            width: 200
          },
          {
            headerName: 'ID Holding',
            valueGetter: holdingIdGetter,
            filter: false,
            width: 200
          },
          {
            headerName: 'Holding agencia',
            valueGetter: holdingNameGetter,
            filter: true,
            width: 200
          },
          {
            headerName: 'Dirección',
            valueGetter: addressGetter,
            filter: true,
            width: 200
          },
          {
            headerName: 'Número de identificación',
            valueGetter: () => '-',
            filter: true,
            width: 200
          },
    ]
  },
  {
    headerName: 'Datos Facturación',
    headerClass: 'column-group-2',
    children: [
      //queda
      {
        headerName: 'Modo de facturación',
        valueGetter: billingMethodGetter,
        filter: false,
        width: 200
      },
      //queda
      {
        headerName: 'Modo de pago',
        valueGetter: paymentODOOMethodGetter,
        filter: false,
        width: 200
      },
      //queda
      {
        headerName: 'Plazo de pago',
        valueGetter: timeLimitGetter,
        filter: false,
        width: 200
      },
      {
        headerName: 'Plazo de cobro',
        valueGetter: () => '-',
        filter: false,
        width: 200
      },
      //queda
      {
        headerName: 'Modo comisión agencia',
        valueGetter: agencyCommissionMethodGetter,
        filter: false,
        width: 250
      }
    ]
  }
]
