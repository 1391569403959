var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "vx-card",
        { staticClass: "campaigns-container" },
        [
          _c(
            "vx-card",
            {
              staticClass: "mb-2 campaign-filters",
              attrs: { title: "Filtros" },
            },
            [
              _c(
                "vs-row",
                {
                  attrs: {
                    "vs-align": "flex-end",
                    "vs-type": "flex",
                    "vs-justify": "space-between",
                    "vs-w": "12",
                  },
                },
                [
                  _c(
                    "vs-col",
                    { attrs: { "vs-w": "11" } },
                    [
                      _c(
                        "vs-row",
                        { staticClass: "gap-4 items-end" },
                        [
                          _c("vs-col", { attrs: { "vs-w": "2" } }, [
                            _c(
                              "div",
                              { attrs: { id: "payment-filters__date-range" } },
                              [
                                _c("p", [_vm._v("Filtrar por fecha de pago")]),
                                _c("flat-pickr", {
                                  staticClass: "mb-1 w-full",
                                  attrs: {
                                    width: "100%",
                                    config:
                                      _vm.configDateRangeCampaignsTimePicker,
                                    placeholder: "Rango",
                                  },
                                  on: {
                                    "on-change": _vm.onDateRangeCampaignsChange,
                                  },
                                  model: {
                                    value: _vm.paymentDateRange,
                                    callback: function ($$v) {
                                      _vm.paymentDateRange = $$v
                                    },
                                    expression: "paymentDateRange",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _c(
                            "vs-col",
                            { attrs: { "vs-w": "2" } },
                            [
                              _c("holding-select", {
                                on: {
                                  value: function ($event) {
                                    _vm.filters.holdings = $event
                                  },
                                  companies: function ($event) {
                                    _vm.companies = $event
                                  },
                                  clearCompanies: _vm.clearCompanies,
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "vs-col",
                            { attrs: { "vs-w": "2" } },
                            [
                              _c("companies-select", {
                                ref: "companiesSelect",
                                attrs: { companies: _vm.companies },
                                on: {
                                  value: function ($event) {
                                    _vm.filters.companies = $event
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "vs-col",
                    { attrs: { "vs-w": "1" } },
                    [
                      _c(
                        "vs-col",
                        { attrs: { "vs-w": "12" } },
                        [
                          _c(
                            "vs-button",
                            {
                              staticClass: "mr-3",
                              on: { click: _vm.searchData },
                            },
                            [_vm._v("Buscar")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("payments-table", {
            ref: "payments",
            attrs: { filters: _vm.filters },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }