var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "vs-select",
    {
      staticClass: "w-full",
      attrs: { id: "multi-month-filter" },
      on: { input: _vm.onOptionChanged },
      model: {
        value: _vm.selectedOption,
        callback: function ($$v) {
          _vm.selectedOption = $$v
        },
        expression: "selectedOption",
      },
    },
    [
      _c("vs-select-item", { attrs: { value: null, text: "Todos" } }),
      _c("vs-select-item", { attrs: { value: 1, text: "Sí" } }),
      _c("vs-select-item", { attrs: { value: 0, text: "No" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }