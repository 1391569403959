<template>
      <vs-select 
        v-model="selectedOption" 
        @input="onOptionChanged" 
        id="multi-month-filter"
        class="w-full"
      >
          <vs-select-item :value="null" :text="'Todos'" />
          <vs-select-item :value="1" :text="'Sí'" />
          <vs-select-item :value="0" :text="'No'" />
      </vs-select>
</template>
  
  <script>
  import Vue from 'vue'
  export default Vue.extend({
    data() {
      return {
        selectedOption: null,
      };
    },
    methods: {
      onOptionChanged() {
        this.params.handleFilter({
          [this.params.columnName]: this.selectedOption,
        });
      },
    },
    mounted() {
      // If the filter is not applied, set the selected option to null
      const values = this.params.getValues()
      // If the filter is applied, set the selected option to the value of the filter
      if (values[this.params.columnName] !== null && values[this.params.columnName] !== undefined) {
        this.selectedOption = values[this.params.columnName]
      }else if (this.params.defaultValue !== null) {
        this.selectedOption = this.params.defaultValue
      }
    }
  });
  </script>
  