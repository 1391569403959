<template>
    <vs-select
        id="programmatic-provider"
        class="w-full"
        :clearable="false"
        multiple
        @input="setProviders"
        v-model="providersSelected"
    >
        <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item, index) in providers" />
        <span slot="no-options">Nada que mostrar.</span>
    </vs-select>
</template>
  
<script>
    import Vue from 'vue'
    
    export default Vue.extend({
    data () {
        return {
            providers: [
                { value: 'all', text: 'Todos' },
                { value: 1, text: 'LatinAD' },
                { value: 2, text: 'Place Exchange' },
                { value: 3, text: 'Magnite' },
                { value: 4, text: 'Perion' },
                { value: 5, text: 'Beyeond' },
                { value: 6, text: 'Outcon' },
                { value: 7, text: 'Taggify' },
                { value: 8, text: 'Moving Walls' },
                { value: 9, text: 'Vistar Media' },
                { value: 10, text: 'Prodooh' }
            ],
            providersSelected: ['all'],
            loading: false
        }
    },
    methods: {
        setProviders () {
            this.existOptionAll()
            this.params.handleFilter({
                'programmatic_providers': this.providersSelected.includes('all') ? null : this.providersSelected,
            });
        },
        existOptionAll () {
            const positionOfAll = this.providersSelected.indexOf('all')
            if (positionOfAll === 0) this.providersSelected.splice(positionOfAll, 1)
            if ((positionOfAll > 0) || (this.providersSelected.length === 0)) this.providersSelected = ['all']
        }
    },
    mounted() {
        const values = this.params.getValues()
        if (values.programmatic_providers !== null && values.programmatic_providers !== undefined) {
            this.providersSelected = values.programmatic_providers
        }
    }
})
</script>
