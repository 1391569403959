var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "h-screen flex w-full bg-img vx-row no-gutter items-center justify-center",
      attrs: { id: "page-login" },
    },
    [
      _c(
        "div",
        {
          staticClass: "vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4",
        },
        [
          _c("vx-card", [
            _c(
              "div",
              {
                staticClass: "full-page-bg-color",
                attrs: { slot: "no-body" },
                slot: "no-body",
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "vx-row no-gutter justify-center items-center",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "vx-col hidden lg:block lg:w-1/2" },
                      [
                        _c("img", {
                          staticClass: "mx-auto",
                          attrs: {
                            src: require("@/assets/images/pages/login.png"),
                            alt: "login",
                          },
                        }),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "vx-col sm:w-full md:w-full lg:w-1/2 d-theme-dark-bg",
                      },
                      [
                        _c("div", { staticClass: "p-8 login-tabs-container" }, [
                          _c("div", { staticClass: "vx-card__title mb-4" }, [
                            _c("h4", { staticClass: "mb-4" }, [
                              _vm._v("Iniciar sesión"),
                            ]),
                            _c("p", [
                              _vm._v(
                                "Bienvenido! Ingresá con tu email y contraseña."
                              ),
                            ]),
                          ]),
                          _c(
                            "form",
                            {
                              on: {
                                submit: function ($event) {
                                  $event.preventDefault()
                                  return _vm.submitForm.apply(null, arguments)
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                [
                                  _c("vs-input", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required",
                                        expression: "'required'",
                                      },
                                    ],
                                    staticClass: "w-full",
                                    attrs: {
                                      "data-vv-validate-on": "disable",
                                      name: "email",
                                      "icon-no-border": "",
                                      icon: "icon icon-user",
                                      "icon-pack": "feather",
                                      "label-placeholder": "Email",
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.clear2FA()
                                      },
                                    },
                                    model: {
                                      value: _vm.email,
                                      callback: function ($$v) {
                                        _vm.email = $$v
                                      },
                                      expression: "email",
                                    },
                                  }),
                                  _c(
                                    "span",
                                    { staticClass: "text-danger text-sm" },
                                    [_vm._v(_vm._s(_vm.errors.first("email")))]
                                  ),
                                  _c("vs-input", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required|min:6",
                                        expression: "'required|min:6'",
                                      },
                                    ],
                                    staticClass: "w-full mt-6",
                                    attrs: {
                                      "data-vv-validate-on": "disable",
                                      type: "password",
                                      name: "password",
                                      "icon-no-border": "",
                                      icon: "icon icon-lock",
                                      "icon-pack": "feather",
                                      "label-placeholder": "Contraseña",
                                    },
                                    model: {
                                      value: _vm.password,
                                      callback: function ($$v) {
                                        _vm.password = $$v
                                      },
                                      expression: "password",
                                    },
                                  }),
                                  _c(
                                    "span",
                                    { staticClass: "text-danger text-sm" },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.errors.first("password"))
                                      ),
                                    ]
                                  ),
                                  _vm.enable2FA
                                    ? _c(
                                        "div",
                                        [
                                          _c("vs-input", {
                                            directives: [
                                              {
                                                name: "validate",
                                                rawName: "v-validate",
                                                value: "required|min:6",
                                                expression: "'required|min:6'",
                                              },
                                            ],
                                            staticClass: "w-full mt-6",
                                            attrs: {
                                              "data-vv-validate-on": "disable",
                                              autocomplete: "off",
                                              name: "code",
                                              "icon-no-border": "",
                                              icon: "icon icon-lock",
                                              "icon-pack": "feather",
                                              "label-placeholder":
                                                "Codigo de Seguridad",
                                            },
                                            model: {
                                              value: _vm.code2FA,
                                              callback: function ($$v) {
                                                _vm.code2FA = $$v
                                              },
                                              expression: "code2FA",
                                            },
                                          }),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "text-danger text-sm",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.errors.first("code"))
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flex flex-wrap justify-between my-5",
                                    },
                                    [
                                      _c(
                                        "vs-checkbox",
                                        {
                                          staticClass: "mb-3",
                                          model: {
                                            value: _vm.remember,
                                            callback: function ($$v) {
                                              _vm.remember = $$v
                                            },
                                            expression: "remember",
                                          },
                                        },
                                        [_vm._v("Recordar")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "vs-button",
                                    { attrs: { button: "submit" } },
                                    [_vm._v("Ingresar")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]),
                      ]
                    ),
                  ]
                ),
              ]
            ),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }